import { faCloudUpload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Box, Typography, Button, LinearProgress } from "@mui/material";
import { FC, useEffect, useRef, useState, useCallback } from "react";
import { useFileUploadMutation } from "../../../api/queryHooks";
import { toast } from "../Toast";
import "./styles.css";
import { useDataContext } from "../../../context/DataContext";
import { Auth } from "aws-amplify";
import React from "react";
import { abortTask } from "../../../utils/workers";

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  webkitdirectory?: string;
  directory?: string;
}

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  dataStore: {
    type: "s3" | "azureblob";
    name: string;
    base_path: string;
    credentials:
      | {
          access_key_id: string;
          secret_access_key: string;
        }
      | {
          client_id: string;
          client_secret: string;
          tenant_id: string;
        };
  };
};

const CHUNK_SIZE = 50; // Number of files to upload in each chunk

export const FileUploadModal: FC<Props> = ({
  onClose,
  dataStore,
  onSuccess,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<number>(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);

  const {
    uploadFileTaskProgress,
    uploadFileTask,
    setUploadFileTaskProgress,
    setUploadFileTask,
  } = useDataContext();

  const resetAndClose = () => {
    setFiles([]);
    setTotalFiles(0);
    setUploadedFiles(0);
    onClose();
  };

  const filesUploadMutation = useFileUploadMutation(
    { storage: dataStore },
    {
      onSuccess: () => {
        setUploadedFiles((prev) => prev + CHUNK_SIZE);

        if (uploadedFiles + CHUNK_SIZE >= totalFiles) {
          resetAndClose();
          onSuccess();
          toast.success({
            title: "Files uploaded successfully",
            description: "",
          });
        }
      },
      onError: () => {
        toast.error({
          title: "Failed to upload files",
          description: "Try again",
        });
      },
    },
  );

  const readDirectory = async (dir: FileSystemDirectoryEntry) => {
    const readers = dir.createReader();

    return await new Promise<File[]>((resolve) => {
      const entries: File[] = [];
      readers.readEntries(async (results) => {
        if (!results.length) {
          return entries;
        }

        for (const entry of results) {
          if (entry instanceof FileSystemFileEntry) {
            const fileEntry = await new Promise<File>((resolve, reject) => {
              entry.file(resolve, reject);
            });
            entries.push(fileEntry);
          } else if (entry instanceof FileSystemDirectoryEntry) {
            const children = await readDirectory(entry);
            entries.push(...children);
          }
        }

        resolve(entries);
      });
    });
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const entries: File[] = [];

    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      const item = e.dataTransfer.items[i];
      const entry = item.webkitGetAsEntry() as FileSystemEntry;

      if (!entry) {
        continue;
      }

      if (entry instanceof FileSystemFileEntry) {
        entry.file((fileObj) => {
          entries.push(fileObj);
        });
      } else if (entry instanceof FileSystemDirectoryEntry) {
        const files = await readDirectory(entry);

        entries.push(...files);
      }
    }

    setFiles(entries);
  };

  const uploadChunk = useCallback(
    async (chunk: File[]) => {
      await filesUploadMutation.mutateAsync({ files: chunk });
    },
    [filesUploadMutation],
  );

  const submit = async () => {
    if (!files.length || filesUploadMutation.isPending) return;

    setTotalFiles(files.length);
    setUploadedFiles(0);

    for (let i = 0; i < files.length; i += CHUNK_SIZE) {
      const chunk = files.slice(i, i + CHUNK_SIZE);
      await uploadChunk(chunk);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleFileRemoval = (filename: string) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  const FileSelectButton: FC<{ isFolder?: boolean }> = ({ isFolder }) => (
    <label className="bg-primary text-white font-bold py-2 px-4 rounded cursor-pointer hover:opacity-80 transition duration-300 ease-in-out flex items-center justify-center">
      <FontAwesomeIcon icon={faCloudUpload} className="mr-2" />
      <span>{isFolder ? "SELECT FOLDER" : "SELECT FILES"}</span>
      <input
        {...({
          type: "file",
          multiple: !isFolder,
          webkitdirectory: isFolder ? "" : undefined,
          directory: isFolder ? "" : undefined,
          onChange: handleFileChange,
          className: "hidden",
          accept: isFolder ? undefined : "image/jpeg,image/gif,image/png,.pdf,.doc,.docx,.txt,.xlsx,.xls,.pptx"
        } as CustomInputProps)}
      />
    </label>
  );

  useEffect(() => {
    if (folderInputRef.current !== null) {
      folderInputRef.current.setAttribute("directory", "");
      folderInputRef.current.setAttribute("webkitdirectory", "");
    }
  }, [folderInputRef, fileInputRef]);

  return (
    <Modal open onClose={resetAndClose}>
      <Box className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <Box className="bg-white rounded-lg shadow-xl p-8 max-w-2xl w-full mx-4">
          {filesUploadMutation.isPending || uploadFileTaskProgress !== null ? (
            <Box className="space-y-4">
              <Typography variant="h5" className="font-bold text-primary">
                Upload Progress
              </Typography>
              <LinearProgress
                variant="determinate"
                value={(uploadedFiles / totalFiles) * 100}
              />
              <Typography align="center">
                {uploadedFiles} / {totalFiles} files uploaded ({((uploadedFiles / totalFiles) * 100).toFixed(2)}%)
              </Typography>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={async () => {
                  await abortTask(
                    uploadFileTask,
                    await Auth.currentAuthenticatedUser().then((user) => user.username),
                    "Failed to stop the data upload process.",
                    "The data uploading process has been successfully stopped."
                  );
                  setUploadFileTaskProgress(null);
                  setUploadFileTask(null);
                  resetAndClose();
                }}
              >
                Abort Upload
              </Button>
            </Box>
          ) : (
            <Box className="space-y-6">
              <Typography variant="h5" className="font-bold text-primary">
                Upload Files
              </Typography>
              <Box
                className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:border-primary transition duration-300 ease-in-out"
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDrop={handleDrop}
              >
                <Typography className="mb-4">Drag and drop files here, or</Typography>
                <Box className="flex justify-center space-x-4">
                  <FileSelectButton />
                  <FileSelectButton isFolder />
                </Box>
              </Box>
              {files.length > 0 && (
                <Box>
                  <Typography variant="h6" className="font-semibold mb-2">
                    Selected Files ({files.length})
                  </Typography>
                  <Box className="max-h-48 overflow-auto bg-gray-50 rounded-lg p-2 space-y-1">
                    {files.map((file) => (
                      <Box
                        key={file.name}
                        className="flex justify-between items-center py-2 px-3 hover:bg-gray-100 rounded transition duration-300 ease-in-out"
                      >
                        <Typography noWrap className="max-w-xs">
                          {file.name}
                        </Typography>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          onClick={() => handleFileRemoval(file.name)}
                          className="cursor-pointer text-red-500 hover:text-red-700 transition duration-300 ease-in-out"
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
              <Box className="flex justify-end space-x-4">
                <Button variant="outlined" color="primary" onClick={resetAndClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  disabled={!files.length}
                >
                  Upload
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
