import { useEffect, useCallback, useState, useMemo, useContext } from "react";
import { Document, Page } from "react-pdf";
import { toast } from "../Toast";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { colors } from "../../../twExtend";
import { throttle } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { sendRequest } from "../functions/api";
import Auth from "../../../auth/AuthProvider";
import { ENDPOINTS } from "../../../api/endpoints";
import { useAtom } from "jotai";
import { pdfSearchAtom, pdfAtom } from "../../../atoms";

export const DocumentViewer = ({ path, data_store_name }) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1.3);
  const [searchResults, setSearchResults] = useState([]);
  const [isEvidenceCollapsed, setIsEvidenceCollapsed] = useState(false);
  const [sliderValue, setSliderValue] = useState(1);
  const [expandedEvidence, setExpandedEvidence] = useState(null);
  const [hasEvidence, setHasEvidence] = useState(true);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfData, setPdfData] = useState(null);
  const [fetchingPdfData, setFetchingPdfData] = useState(false);
  const { usedCatalog, preferences, setShowFileOnPage } =
    useContext(DataContext);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  const onClose = () => {
    setPdf("");
    setShowFileOnPage(null);
    setPdfSearch({
      evidence: [""],
      chunk_index: "",
    });
  };

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const cleanPath = path.replace(/\/{2,}/g, "/");
        const username = (await Auth.currentAuthenticatedUser()).username;
        const catalog_name = usedCatalog;
        const search = JSON.stringify(pdfSearch);
        const entry_data_store = {
          ...preferences.webapp_profile.DATA_STORES[data_store_name],
          path: cleanPath,
        };
        setFetchingPdfData(true); 
        const response = await sendRequest(
          {
            username,
            catalog_name,
            file_name: cleanPath.split('/').pop(),
            search: pdfSearch,
            entry_data_store,
          },
          ENDPOINTS["fetch_catalog_content"],
        );
        const result = await response.json();
        setFetchingPdfData(false);
        setPdfData(result.pdf_content);

        const pages = result.pages;
        if (
          pages.length === 0 ||
          pages[0].length === 0 ||
          typeof pages[0][0] !== "number"
        ) {
          setPageNumber(1);
          setHasEvidence(false);
          if (Array.isArray(search?.evidence) && search.evidence.length > 0) {
            toast.info({
              title: "Info",
              description: "No evidence highlighted in the document.",
            });
          }
        } else {
          setSearchResults(pages);
          setHasEvidence(true);
          setPageNumber(pages[0][0] + 1);
          setTimeout(() => {
            scrollToPage(pages[0][0] + 1);
          }, 1000);
        }
      } catch (error) {
        setFetchingPdfData(false);
        setPageNumber(1);
        toast.error({
          title: "Error",
          description: "Failed to load search results.",
        });
      }
    };

    fetchPdfData();
  }, [pdfSearch, path]);

  const scrollToPage = (pageNumber) => {
    const pageElement = document.getElementById(`page_${pageNumber}`);
    if (pageElement) {
      pageElement.scrollIntoView({ behavior: "smooth" });
      setPageNumber(pageNumber);
    }
  };

  const scrollToPosition = useCallback(
    (value) => {
      const container = document.querySelector(".flex-1.overflow-auto");
      if (container) {
        const totalHeight = container.scrollHeight - container.clientHeight;
        const scrollPosition = totalHeight * ((value - 1) / (numPages - 1));
        container.scrollTo({ top: scrollPosition, behavior: "auto" });
      }
    },
    [numPages],
  );

  const throttledScrollToPosition = useMemo(
    () => throttle(scrollToPosition, 16),
    [scrollToPosition],
  );

  const handleSliderChange = useCallback(
    (value) => {
      setSliderValue(value);
      throttledScrollToPosition(value);
      setPageNumber(Math.round(value));
    },
    [throttledScrollToPosition],
  );

  const handleScroll = useMemo(
    () =>
      throttle((e) => {
        const container = e.target;
        const scrollTop = container.scrollTop;
        const totalHeight = container.scrollHeight - container.clientHeight;
        const scrollPercentage = scrollTop / totalHeight;
        const currentValue = 1 + scrollPercentage * (numPages - 1);

        setSliderValue(currentValue);
        setPageNumber(Math.round(currentValue));
      }, 16),
    [numPages],
  );

  useEffect(() => {
    return () => {
      handleScroll.cancel();
      throttledScrollToPosition.cancel();
    };
  }, [handleScroll, throttledScrollToPosition]);

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 backdrop-blur-lg overflow-hidden z-[999999]"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="w-full max-w-7xl p-6 flex flex-col gap-6 justify-center items-center bg-white shadow-lg rounded-lg relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-rose-500 px-4 py-2 text-white rounded-md shadow hover:bg-rose-600 transition"
        >
          X
        </button>
        <div className="flex w-full h-[75vh] pt-[4vh]">
          <div
            className="flex-1 overflow-auto p-4 bg-gray-50 rounded-lg shadow-inner hide-scrollbar relative"
            onScroll={handleScroll}
          >
            {!fetchingPdfData ? (
              <Document
                file={pdfData}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <div className="absolute inset-0 flex justify-center items-center flex-col gap-2">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
                    <p>Loading...</p>
                  </div>
                }
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    key={index}
                    id={`page_${index + 1}`}
                    className={`mb-4 ${
                      Math.round(sliderValue) === index + 1
                        ? "ring-2 ring-primary"
                        : ""
                    }`}
                  >
                    <Page pageNumber={index + 1} scale={zoom} />
                  </div>
                ))}
              </Document>
            ) : (
              <div className="absolute inset-0 flex justify-center items-center flex-col gap-2">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
                <p>Loading...</p>
              </div>
            )}
          </div>
          <div className="w-72 p-4 bg-gray-100 rounded-lg shadow-lg ml-4 space-y-4 overflow-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-grey text-md">Evidence</h3>
              <button
                className="text-gray-500 border-2 p-2 rounded-full border-gray-300 hover:bg-gray-200 transition"
                onClick={() => setIsEvidenceCollapsed(!isEvidenceCollapsed)}
              >
                {isEvidenceCollapsed ? <FaChevronDown /> : <FaChevronUp />}
              </button>
            </div>
            {!isEvidenceCollapsed &&
              (hasEvidence ? (
                searchResults.map((result, index) => (
                  <div key={index} className="mb-4">
                    <div
                      className={`p-3 text-base rounded-lg shadow-md cursor-pointer hover:bg-gray-200 transition ${
                        result[0] + 1 === pageNumber
                          ? "bg-gray-300 text-gray-800"
                          : "bg-white"
                      }`}
                      onClick={() => {
                        scrollToPage(result[0] + 1);
                        setExpandedEvidence(
                          expandedEvidence === index ? null : index,
                        );
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <span>
                          {result[1].length} evidence on page {result[0] + 1}
                        </span>
                        {expandedEvidence === index ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </div>
                    </div>
                    {expandedEvidence === index && (
                      <div className="mt-2 p-3 bg-white rounded-lg shadow-inner">
                        {result[1].map((highlight, hIndex) => (
                          <div
                            key={hIndex}
                            className="text-sm text-gray-600 mb-2 last:mb-0 break-words"
                          >
                            {highlight}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="text-gray-600 text-center">
                  No evidence found in the document.
                </div>
              ))}
          </div>
        </div>
        <div className="w-full flex flex-col items-center mt-4">
          <div className="flex w-full justify-between items-center gap-3">
            <button
              className="bg-secondary text-base px-4 py-2 text-white rounded-md shadow hover:bg-secondary-dark transition"
              onClick={(e) => {
                e.stopPropagation();
                const newPageNumber =
                  (pageNumber || 1) - 1 > 0 ? pageNumber - 1 : 1;
                scrollToPage(newPageNumber);
              }}
            >
              Previous
            </button>
            <button
              className="bg-primary text-base px-4 py-2 text-white rounded-md shadow hover:bg-primary-dark transition"
              onClick={(e) => {
                e.stopPropagation();
                const newPageNumber =
                  (pageNumber || 1) + 1 <= numPages ? pageNumber + 1 : numPages;
                scrollToPage(newPageNumber);
              }}
            >
              Next
            </button>
            <div className="flex-1 mx-8">
              <Slider
                min={1}
                max={numPages}
                step={0.01}
                value={sliderValue}
                onChange={handleSliderChange}
                tipFormatter={(value) => `Page ${Math.round(value)}`}
                railStyle={{ backgroundColor: colors.grey, height: 4 }}
                handleStyle={{
                  height: 24,
                  width: 24,
                  marginLeft: -12,
                  marginTop: -10,
                  backgroundColor: colors.primary,
                  border: "none",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                }}
                trackStyle={{
                  backgroundColor: colors.primary,
                  height: 4,
                }}
              />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <div className="bg-white text-sm rounded-md shadow px-4 py-2">
              Page {pageNumber} of {numPages}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
