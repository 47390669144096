import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import {
  addNewCatalog,
  deleteCatalog,
} from "../../../../../utilities/functions/apiCalls";
import { useCatalogNames } from "../../../../../../api/queryHooks";
import { toast } from "../../../../../utilities/Toast";

type DataContextType = {
  catalogNames: string[];
  setCatalogNames: React.Dispatch<React.SetStateAction<string[]>>;
  setCatalogGetsCreated: any;
  setShowScreen: any;
  handleCatalogRename: (
    oldName: string,
    newName: string,
  ) => Promise<{ success: boolean }>;
};

export function CatalogComponent() {
  const { data: catalogNames = [] } = useCatalogNames();
  const { handleCatalogRename, setCatalogGetsCreated, setShowScreen } =
    useContext<DataContextType>(DataContext);
  const [catalogList, setCatalogList] = useState(catalogNames);
  const [filteredCatalogs, setFilteredCatalogs] = useState(catalogNames);
  const [newCatalogName, setNewCatalogName] = useState("");
  const [editingName, setEditingName] = useState<string | null>(null);
  const [editedName, setEditedName] = useState("");
  const [updatingCatalog, setUpdatingCatalog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredCatalogs(
      catalogList.filter((catalog) =>
        catalog.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, catalogList]);

  const addCatalog = () => {
    if (newCatalogName && !catalogList.includes(newCatalogName)) {
      addNewCatalog(newCatalogName);
      const updatedCatalogs = [...catalogList, newCatalogName];
      setCatalogList(updatedCatalogs);
      setNewCatalogName("");
    }
  };

  const deleteFromCatalog = (name: string) => {
    const isConfirmed = window.confirm(
      `Deleting '${name}' will remove access to files in this catalog. Are you sure you want to proceed?`,
    );

    if (isConfirmed) {
      deleteCatalog(name)
        .then(() => {
          const updatedCatalogs = catalogList.filter(
            (catalog) => catalog !== name,
          );
          setCatalogList(updatedCatalogs);
          toast.success({
            title: "Success",
            description: `Catalog '${name}' deleted successfully!`,
          });
        })
        .catch((error) => {
          console.error("Failed to delete catalog:", error);
          toast.error({
            title: "Error",
            description: `Failed to delete catalog '${name}'. Please try again.`,
          });
        });
    }
  };

  const startEditing = (name: string) => {
    setEditedName(name);
    setEditingName(name);
  };

  const applyEdit = async (oldName: string) => {
    if (oldName === editedName || !editedName.trim()) {
      setEditingName(null);
      setEditedName("");
      return;
    }
    setUpdatingCatalog(true);
    try {
      const response = await handleCatalogRename(oldName, editedName);
      if (!response.success) {
        toast.error({
          title: "Error",
          description:
            editedName === "Global"
              ? "'Global' is a reserved catalog name."
              : "Failed to rename catalog. Please try again.",
        });
        setEditingName(null);
        setEditedName("");
        throw new Error("Failed to rename catalog");
      }
      const updatedCatalogs = [...catalogList];
      const index = updatedCatalogs.indexOf(oldName);
      if (index !== -1) {
        updatedCatalogs[index] = editedName;
        setCatalogList(updatedCatalogs);
      }
      setEditingName(null);
      setEditedName("");
    } catch (error) {
      console.error("Failed to update catalog name:", error);
    }
    setUpdatingCatalog(false);
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg text-sm">
      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          className="form-input border border-gray-300 rounded-lg shadow-sm p-3 flex-grow mr-2"
          placeholder="Search catalogs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="flex items-center gap-3">
          <button
            className="px-4 py-2 bg-primary hover:bg-deasieTurquoise text-white rounded-lg shadow"
            onClick={() => {
              setCatalogGetsCreated(true);
              setShowScreen("catalog");
            }}
          >
            Add
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {filteredCatalogs.map((name) => (
          <div
            key={name}
            className="flex items-center justify-between bg-gray-100 p-3 rounded-lg shadow-md"
          >
            {editingName === name ? (
              updatingCatalog ? (
                <span className="text-gray-500">Updating...</span>
              ) : (
                <input
                  type="text"
                  className="form-input flex-grow border border-gray-300 rounded-lg shadow-sm p-3"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  onBlur={() => applyEdit(name)}
                  onKeyPress={(e) => e.key === "Enter" && applyEdit(name)}
                />
              )
            ) : (
              <span className="text-gray-800 truncate">{name}</span>
            )}
            <div className="flex items-center">
              {editingName !== name && !updatingCatalog && (
                <button
                  className="ml-2 text-blue-500 hover:text-blue-700"
                  onClick={() => startEditing(name)}
                >
                  ✎
                </button>
              )}
              {editingName !== name && !updatingCatalog && (
                <button
                  className="ml-2 text-red-500 hover:text-red-700"
                  onClick={() => deleteFromCatalog(name)}
                >
                  ✕
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
