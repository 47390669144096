import React, { useContext, useState } from "react";
import { FaChevronDown, FaChevronRight, FaCopy } from "react-icons/fa";
import Modal from "../../../../../../../../utilities/Modal/Modal";
import { useAtom } from "jotai";
import { pdfAtom, pdfSearchAtom } from "../../../../../../../../../atoms";
import { DataContext, useDataContext } from "../../../../../../../../../context/DataContext";
import { API_USERNAME_KEYWORD } from "../../../../../../../../../constants/fixedValues";
import Auth from "../../../../../../../../../auth/AuthProvider";
import { sendRequest } from "../../../../../../../../../components/utilities/functions/api";
import { ENDPOINTS } from "../../../../../../../../../api/endpoints";
import { toast } from "../../../../../../../../utilities/Toast";

export const DownloadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V21H19V18H5Z"
      fill="currentColor"
    />
  </svg>
);

const SearchBar = ({ searchQuery, setSearchQuery, filterType, setFilterType }) => {
  const handleQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  return (
    <div className="flex items-center w-1/4 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
      <svg
        className="text-gray-500 ml-2 mr-3"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M21 21l-4.35-4.35M10.5 18a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        />
      </svg>
      <input
        type="text"
        placeholder={`Filter by ${filterType} name`}
        value={searchQuery}
        onChange={handleQueryChange}
        className="flex-1 bg-transparent outline-none text-sm text-gray-700 placeholder-gray-500"
      />
      <select
        value={filterType}
        onChange={handleFilterChange}
        className="border-l border-gray-300 bg-gray-100 p-2 rounded-r-lg text-sm"
      >
        <option value="file">File</option>
        <option value="tag">Tag</option>
      </select>
    </div>
  );
};

const FileDropdown = ({ file }) => {
  const [isFileOpen, setIsFileOpen] = useState(false);

  return (
    <div className="mb-6 bg-white rounded-lg shadow-md overflow-hidden">
      <div
        className="flex items-center justify-between bg-gray-300 p-3 cursor-pointer hover:bg-gray-200 transition-colors duration-200"
        onClick={() => setIsFileOpen(!isFileOpen)}
      >
        <div className="flex items-center">
          {isFileOpen ? (
            <FaChevronDown className="text-gray-600 mr-2" />
          ) : (
            <FaChevronRight className="text-gray-600 mr-2" />
          )}
          <span className="font-semibold text-gray-800">{file.fileName}</span>
        </div>
        <span className="text-sm text-gray-500">{file.tags.length} tags</span>
      </div>
      {isFileOpen && (
        <div className="p-4">
          <table className="w-full rounded-lg overflow-hidden shadow-md">
            <thead>
              <tr className="bg-gray-300 text-left text-gray-700 text-sm font-medium">
                <th className="px-4 py-2">Tag</th>
                <th className="px-4 py-2">Value</th>
                <th className="px-4 py-2">Evidence</th>
                <th className="px-4 py-2">Is Correct</th>
                <th className="px-4 py-2">Is Submitted</th>
              </tr>
            </thead>
            <tbody>
              {file.tags.map((tag, tagIndex) => (
                <TagDropdown key={tagIndex} tag={tag} tag_uuid={tag.tag_uuid} filename={file.fileName} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};


const EvidenceDropdown = ({ evidenceList, onEvidenceClick }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success({ title: "Copied to clipboard" });
      },
      (err) => {
        console.error("Could not copy text: ", err);
        toast.error({ title: "Failed to copy" });
      }
    );
  };

  return (
    <tr>
      <td colSpan="2"></td>
      <td colSpan="3" className="px-4 py-2">
        <div className="mt-1 text-gray-600 max-w-2/5">
          {evidenceList.length > 0 ? (
            <div className="rounded-lg shadow-md overflow-hidden border border-gray-300">
              <table className="min-w-full bg-white">
                <thead >
                  <tr className="bg-gray-300 text-left text-gray-700 text-sm font-medium">
                    <th className="px-4 py-2 w-3/5">
                      Evidence
                    </th>
                    <th className="px-4 py-2 w-1/5">
                      Page range
                    </th>
                  </tr> 
                </thead>
                <tbody>
                  {evidenceList.map((evidenceItem, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => onEvidenceClick(evidenceItem.evidence)}
                    >
                      <td className="px-4 py-2 border-b border-gray-200 text-sm text-left">
                      <div className="border border-gray-300 bg-gray-100 rounded-lg p-3 shadow-sm hover:shadow-md transition-shadow duration-200 relative group">
                        <div className="pr-8">{evidenceItem.evidence}</div>
                        <button
                          className="absolute top-1 right-1 p-1 text-gray-400 hover:text-gray-600 transition-colors opacity-50 group-hover:opacity-100"
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(evidenceItem.evidence);
                          }}
                          title="Copy to clipboard"
                        >
                          <FaCopy className="w-4 h-4" />
                        </button>
                        </div>
                      </td>
                      <td className="px-4 py-2 border-b border-gray-200">
                        {evidenceItem.pageRange}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-gray-600 text-center py-2">No evidence available</div>
          )}
        </div>
      </td>
    </tr>
  );
};

const formatPageRange = (pageRange, chunk_index, total_size, page_count) => {
  const validPageRange = pageRange && pageRange.length > 0;
  if (
    !validPageRange &&
    Array.isArray(total_size) &&
    total_size.length === 1 &&
    Number.isFinite(total_size[0]) &&
    Array.isArray(page_count) &&
    page_count.length === 1 &&
    Number.isFinite(page_count[0])
  ) {
    const char_start = chunk_index.split("_")[0];
    const char_end = chunk_index.split("_")[1];
    const chars_per_page = total_size[0] / page_count[0];
    pageRange = [
      Math.floor(char_start / chars_per_page) + 1,
      Math.min(Math.floor(char_end / chars_per_page) + 1, page_count[0]),
    ];
  } else if (!pageRange || pageRange.length === 0) {
    return "N/A";
  }

  if (pageRange.length === 1) return pageRange[0].toString();
  if (pageRange[0] === pageRange[1]) return pageRange[0].toString();
  return `${pageRange[0]} - ${pageRange[pageRange.length - 1]}`;
};

const TagDropdown = ({ tag, filename }) => {
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [evidenceList, setEvidenceList] = useState([]);
  const { usedCatalog, setShowFileOnPage, catalogFiles } = useContext(DataContext);
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [, setPdfSearch] = useAtom(pdfSearchAtom);
  const [loadingEvidence, setLoadingEvidence] = useState(false);

  const { documentPreviewDatastoreName, setDocumentPreviewDatastoreName } = useDataContext();

  const handleShowEvidence = async () => {
    if (isTagOpen) {
      setIsTagOpen(false);
      return;
    }
    setLoadingEvidence(true);
    setIsTagOpen(true);
  
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
        catalog_name: usedCatalog,
        file_name: filename,
        tag_uuid: tag.tag_uuid,
        tag_value: JSON.stringify(tag.tagValue),
      };
      const response = await sendRequest(
        sendDetails,
        ENDPOINTS["get_document_level_evidence"]
      );
      const evidence_chunks = await response.json();
  
      const evidenceArray = evidence_chunks.document_level_evidence.map((chunk) => {
        const pageRange = formatPageRange(
          chunk.pageRange,
          chunk.chunk_id,
          catalogFiles[filename].total_size,
          catalogFiles[filename].page_count
        );
  
        return {
          evidence: chunk.chunk_evidence,
          chunk_id: chunk.chunk_id,
          pageRange, // Include the calculated pageRange
        };
      });
  
      setEvidenceList(evidenceArray);
    } catch (error) {
      console.error("Failed to fetch evidence:", error);
    } finally {
      setLoadingEvidence(false);
    }
  };

  const cleanTagValue = Array.isArray(tag.tagValue)
    ? tag.tagValue.map(value => value.replace(/['"]/g, ''))
    : tag.tagValue.replace(/['"]/g, '');


  const handleEvidenceClick = async (evidence) => {
    toast.info({ title: "Loading document..." });
    try {
      const selectedEvidence = evidenceList.find(
        (item) => item.evidence === evidence
      );  
      const payload =
      {
        tag_uuid: tag.tag_uuid,
        evidence: [evidence],
        chunk_index: selectedEvidence ? [selectedEvidence.chunk_id] : [],
      }
      setShowFileOnPage("standard");
      setPdf(`${catalogFiles[filename].file_directory}/${filename}`);
      setDocumentPreviewDatastoreName(catalogFiles[filename].data_store_name);
      setPdfSearch(payload);
    } catch (error) {
      toast.error({ title: "Failed to load document" });
      console.error("Error loading document:", error);
    }
  };

  return (
    <>
      <tr className="bg-gray-50 text-left text-gray-600">
        <td className="px-4 py-1">{tag.tagName}</td>
        <td className="px-4 py-1 max-w-md whitespace-normal break-words">
          {cleanTagValue}
        </td>
        <td className="px-4 py-1">
            <div
            onClick={handleShowEvidence}
            className={`flex items-center cursor-pointer transition-all ${
              isTagOpen ? "text-primary" : "hover:text-blue-700"
            }`}
          >
            {isTagOpen ? <FaChevronDown /> : <FaChevronRight />}{" "}
            <span className="ml-1">
              {isTagOpen ? "hide evidence" : "show evidence"}
            </span>
          </div>
        </td>
        <td className="px-4 py-2">
          <input type="checkbox" />
        </td>
        <td className="px-4 py-2">
          <input type="checkbox" />
        </td>
      </tr>
      {isTagOpen && (
        <>
          {loadingEvidence ? (
            <tr>
              <td colSpan="5" className="text-center py-4">
                <div className="text-gray-600">Loading evidence...</div>
              </td>
            </tr>
          ) : (
            <EvidenceDropdown
              evidenceList={evidenceList}
              onEvidenceClick={handleEvidenceClick}
            />
          )}
        </>
      )}
    </>
  );
};

export default function DocumentEvidenceTable(props) {
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState('file');

  const {
    currentDataGroup,
    availableTags,
    ruleDict,
    hiddenCategories,
    usedCatalog,
    catalogFiles,
  } = useContext(DataContext);

  const handleDownloadDocumentEvidence = async () => {
    if (!filteredEvidenceData || filteredEvidenceData.length === 0) {
      console.error("No data available for download.");
      return;
    }
  
    const csvRows = [
      ["File Name", "Tag", "Value", "Evidence", "Page Range"].join(","),
    ];
  
    const fetchEvidenceForTag = async (file, tag) => {
      try {
        const creds = (await Auth.currentAuthenticatedUser()).username;
        const sendDetails = {
          [API_USERNAME_KEYWORD]: creds,
          catalog_name: usedCatalog,
          file_name: file.fileName,
          tag_uuid: tag.tag_uuid,
          tag_value: JSON.stringify(tag.tagValue),
        };
  
        const response = await sendRequest(sendDetails, ENDPOINTS["get_document_level_evidence"]);
        const evidence_chunks = await response.json();
  
        return evidence_chunks.document_level_evidence.map(chunk => ({
          evidence: chunk.chunk_evidence,
          pageRange: formatPageRange(
            chunk.pageRange,
            chunk.chunk_id,
            catalogFiles[file.fileName].total_size,
            catalogFiles[file.fileName].page_count
          )
        }));
      } catch (error) {
        console.error("Failed to fetch evidence:", error);
        return [];
      }
    };
    const escapeCSV = (text) => {
      if (text == null) return '';
      return `"${text.replace(/"/g, '""')}"`;
    };    

    const promises = filteredEvidenceData.flatMap(file =>
      file.tags.map(tag =>
        fetchEvidenceForTag(file, tag).then(evidenceList =>
          evidenceList.map(evidence =>
            csvRows.push([
              escapeCSV(file.fileName),
              escapeCSV(tag.tagName),
              escapeCSV(Array.isArray(tag.tagValue) ? tag.tagValue.join(", ") : tag.tagValue),
              escapeCSV(evidence.evidence),
              escapeCSV(evidence.pageRange)
            ].join(","))
          )
        )
      )
    );
  
    await Promise.all(promises);
  
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "document-evidence-data.csv");
  
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  
  const evidenceData = Object.entries(currentDataGroup)
    .map(([fileName, tags]) => {
      const filteredTags = Object.entries(tags)
        .filter(([key, value]) => {
          return (
            key !== "chunks" &&
            key !== "file_directory" &&
            !hiddenCategories?.includes(key) &&
            value &&
            Array.isArray(value) &&
            value[0] &&
            key in availableTags.sensitivity.tagger_params.tag_dict === false &&
            Object.keys(
              {
                ...availableTags.llm.tagger_params.tag_dict,
                ...ruleDict,
              } || {},
            ).includes(key)
          );
        })
        .map(([tagName, tagValue]) => {
          const tag_uuid =
            availableTags.llm.tagger_params.tag_dict[tagName]?.tag_uuid || null;
          return { tagName, tagValue, tag_uuid };
        });
      return { fileName, tags: filteredTags };
    })
    .filter(file => file.tags.length > 0);

  const filteredEvidenceData = evidenceData.filter(file => {
    if (filterType === 'file') {
      return file.fileName.toLowerCase().includes(searchQuery.toLowerCase());
    } else if (filterType === 'tag') {
      return file.tags.some(tag =>
        tag.tagName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return false;
  });

  const closeEvidence = () => {
    props.setIsDocumentEvidenceModalOpen(false);
    props.setShowAllEvidence(false);
  };

  return (
    <Modal
      isOpen={props.isDocumentEvidenceModalOpen}
      onClose={() => {
        if (pdf) return;
        closeEvidence();
      }}
      title="Evidence List"
    >
      <div className="flex flex-col justify-center w-full h-full overflow-hidden">
        <div className="flex justify-between items-center w-full p-1 mt-3 ml-5 bg-white">
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filterType={filterType}
            setFilterType={setFilterType}
          />
          <div className="flex items-center mr-8 gap-3">
            <button
              onClick={handleDownloadDocumentEvidence} 
              className="download-button hover:bg-gray-200 rounded-md p-2 flex items-center justify-center"
            >
              <DownloadIcon />
            </button>
            <button
              className="bg-primary text-white py-1 px-4 text-xs rounded hover:bg-primary-dark"
            >
              Save
            </button>
          </div>
        </div>

        <div className="flex w-full h-full justify-center items-start z-50 text-sm overflow-y-auto overflow-x-hidden p-2">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-7xl w-full mx-4">
            {filteredEvidenceData.map((file, fileIndex) => (
              <FileDropdown key={fileIndex} file={file} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
