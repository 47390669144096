import React, { useContext, useState, useRef, useEffect } from "react";
import { useAtom } from "jotai";
import { DataContext } from "../../../../../../../context/DataContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import {
  faFile,
  faFolderOpen,
  faTimes,
  faArrowLeft,
  faGears,
  faFileUpload,
  faCalendar,
  faCheck,
  faInfoCircle,
  faFolder
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import FolderList from "../../../../../../utilities/NavigationBar/FolderList";
import { ENDPOINTS } from "../../../../../../../api/endpoints";
import { sendRequest } from "../../../../../../utilities/functions/api";
import Auth from "../../../../../../../auth/AuthProvider";
import ReactDatePicker from "react-datepicker";
import { toast } from "./../../../../../../utilities/Toast";
import { selectedCatalogItemsAtom } from "../../../../../../../atoms";

const ScheduleSettingComponent = ({
  folders,
  currentFolder,
  setCurrentFolder,
  checkedItems,
  setCheckedItems,
  setShowFilePreview,
  filteredFolderKeys,
  searchText,
  setSearchText,
  setFolders,
  setTimeSelectionModalOpen,
  selectedTagKeys,
  isSmartSelectEnabled,
  setIsSmartSelectEnabled,
  availableTags,
  currentDataGroup,
  usedCatalog,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [minTime, setMinTime] = useState(new Date());
  const [maxTime, setMaxTime] = useState(new Date().setHours(23, 59, 59, 999));
  const [type, setType] = useState("One-time");
  const [integration, setIntegration] = useState("s3");
  const [isExternalOptionsOpen, setIsExternalOptionsOpen] = useState(false);
  const [isFolderListModalOpen, setIsFolderListModalOpen] = useState(false);
  const { preferences } = useContext(DataContext);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [checkedFolders, setCheckedFolders] = useState({});
  const [isConfirmationView, setIsConfirmationView] = useState(false);
  const [selectedFoldersForConfirmation, setSelectedFoldersForConfirmation] = useState({});
  const [selectedCatalogItems, setSelectedCatalogItems] = useAtom(
    selectedCatalogItemsAtom,
  );

  useEffect(() => {
    if (type === "Periodic") {
      setSelectedPeriod("HALF_HOUR");
    }
  }, [type]);

  const fetchFolders = async (integration) => {
    let dataStore = preferences.webapp_profile.DATA_STORES[integration];
    try {
      const folderResponse = await sendRequest(
        {
          data_store: JSON.stringify(dataStore),
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["fetch_folders"],
      );
      const retrievedFolders = await folderResponse.json();

      setFolders(retrievedFolders.folder_list);
    } catch (error) {
      console.error("Error during the request", error);
    }
  };

  const handleIntegrationClick = (key) => {
    setIntegration(key);
    fetchFolders(key);
    setIsFolderListModalOpen(true);
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date &&
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const getMinTime = (date) => {
    const now = new Date();
    if (!date || isToday(date)) {
      return now;
    }
    return new Date().setHours(0, 0, 0, 0);
  };

  const getMaxTime = () => {
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
  };

  useEffect(() => {
    setMinTime(getMinTime(selectedDate));
    setMaxTime(getMaxTime());
  }, [selectedDate]);

  const WarningModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg">
          <div className="text-lg">
            Please select a start date and time before confirming the schedule.
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={onClose}
              className="mt-2 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleCreateCatalogWithFilesAndTags = async (confirmedFolders, selectedOption, singleCatalogName) => {
    const scheduleTime = selectedDate || new Date();
    setIsWarningModalOpen(false);
    setTimeSelectionModalOpen(false);

    const username = (await Auth.currentAuthenticatedUser()).username;
    const selectedFolders = Object.keys(confirmedFolders).filter(folder => confirmedFolders[folder].selectedFiles > 0);

    const getFilesForFolder = (folderPath) => {
      const dataSnapShot = { ...currentDataGroup };
      return Object.entries(checkedItems)
        .filter(([fileName, itemData]) => {
          if (folderPath === "Unfoldered") {
            return itemData.isChecked && (!itemData.folder || itemData.folder === "/");
          }
          return itemData.isChecked && itemData.folder && (itemData.folder === folderPath || itemData.folder.startsWith(`${folderPath}/`));
        })
        .map(([fileName, itemData]) => {
          let fileDetails = dataSnapShot[fileName] || {};
          let dataStore, path;

          // Determine the data store name
          let dataStoreName = '';
          if (itemData.source && preferences.webapp_profile.DATA_STORES[itemData.source]) {
            dataStoreName = itemData.source;
            dataStore = preferences.webapp_profile.DATA_STORES[itemData.source];
          } else if (fileDetails.data_store_name) {
            dataStoreName = Array.isArray(fileDetails.data_store_name)
              ? fileDetails.data_store_name[0]
              : fileDetails.data_store_name;
            dataStore = preferences.webapp_profile.DATA_STORES[dataStoreName];
          } else {
            console.warn(`Data store not found for file: ${fileName}. Using default.`);
            dataStoreName = 'default';
            dataStore = preferences.webapp_profile.DATA_STORES['default'];
          }

          if (fileDetails.file_directory) {
            path = `${fileDetails.file_directory}/${fileName}`;
          } else if (!itemData.folder || itemData.folder === "/") {
            path = `${dataStore.base_path}/${fileName}`;
          } else {
            path = `${dataStore.base_path}${itemData.folder}/${fileName}`;
          }

          path = path.replace(/^\/\//, '/');

          return {
            data_store_name: dataStoreName,
            path: path,
            file_name: fileName,
            storage: dataStore.storage || {}
          };
        })
        .filter(item => item !== null); // Remove any null items (skipped files)
    };

    const availableTagsCopy = JSON.parse(JSON.stringify(availableTags));
    for (const tagKey in availableTagsCopy?.llm?.tagger_params?.tag_dict || {}) {
      if (selectedTagKeys.length === 0 || selectedTagKeys.includes(tagKey)) continue;
      delete availableTagsCopy.llm.tagger_params.tag_dict[tagKey];
    }
    const ensureScheduledPrefix = (name) => {
      if (!name || typeof name !== 'string') return 'Catalog';
      return name;
    };

    const createCatalog = async (catalogName, files) => {
      const finalCatalogName = ensureScheduledPrefix(catalogName);
      const requestData = {
        username: username,
        catalog_name: finalCatalogName,
        files: files,
        tagger_list: availableTagsCopy,
        schedule_at: scheduleTime.toISOString(),
        check_sensitivity: false,
        is_soft_run: false,
        quarantine_name: preferences.system.QUARANTINECATALOG,
      };

      try {
        const response = await sendRequest(
          requestData,
          ENDPOINTS["create_catalog_for_scheduled_folder"]
        );
        const result = await response.json();

        toast.success({
          title: `New catalog "${finalCatalogName}" created and files are being processed`,
          description: `Task ID: ${result.task_id}`
        });
      } catch (error) {
        console.error(`Error creating catalog "${finalCatalogName}":`, error);
        toast.error({
          title: `Failed to create catalog "${finalCatalogName}"`,
          description: error.message
        });
      }
    };
    if (selectedOption === 'createMultipleCatalogs') {
      for (const selectedFolderPath of selectedFolders) {
        const files = getFilesForFolder(selectedFolderPath);
        if (files.length > 0) {
          const catalogName = selectedFolderPath === "Unfoldered" ? usedCatalog : selectedFolderPath;
          await createCatalog(catalogName, files);
        }
      }
    } else {
      const allFiles = selectedFolders.flatMap(folder => getFilesForFolder(folder));
      await createCatalog(singleCatalogName, allFiles);
    }
  };

  const handleCreateCatalogButtonClick = () => {
    const selectedFolders = Object.keys(checkedFolders).filter(folder => checkedFolders[folder]);
    const foldersWithFileCount = selectedFolders.reduce((acc, folder) => {
      const fileCount = Object.values(checkedItems).filter(item =>
        item.isChecked && (
          !item.folder ||
          item.folder === "/" ||
          item.folder === folder ||
          (item.folder && item.folder.startsWith(`${folder}/`))
        )
      ).length;
      acc[folder] = fileCount;
      return acc;
    }, {});

    const unfolderCount = Object.values(checkedItems).filter(item =>
      item.isChecked && (!item.folder || item.folder === "/")
    ).length;
    if (unfolderCount > 0) {
      foldersWithFileCount["Unfoldered"] = unfolderCount;
    }

    setSelectedFoldersForConfirmation(foldersWithFileCount);
    setIsConfirmationView(true);
  };

  const ConfirmationView = ({ folders, onBack, onConfirm, currentCatalogName, checkedItems }) => {
    const [confirmedFolders, setConfirmedFolders] = useState(() => {
      const initialState = {};
      
      Object.entries(checkedItems).forEach(([fileName, item]) => {
        if (item.isChecked) {
          const folderPath = item.folder || 'Unfoldered';
          if (!initialState[folderPath]) {
            initialState[folderPath] = { totalFiles: 0, selectedFiles: 0, isSelected: true };
          }
          initialState[folderPath].totalFiles++;
          initialState[folderPath].selectedFiles++;

          // Update parent folders
          let parentPath = folderPath.split('/').slice(0, -1).join('/');
          while (parentPath) {
            if (!initialState[parentPath]) {
              initialState[parentPath] = { totalFiles: 0, selectedFiles: 0, isSelected: false };
            }
            initialState[parentPath].totalFiles++;
            // Don't increment selectedFiles for parent folders
            parentPath = parentPath.split('/').slice(0, -1).join('/');
          }
        }
      });

      // Second pass remains unchanged
      Object.keys(initialState).forEach(folderPath => {
        const parts = folderPath.split('/');
        if (parts.length > 1) {
          const parentPath = parts.slice(0, -1).join('/');
          if (initialState[parentPath]) {
            initialState[parentPath].isSelected = false;
          }
        }
      });

      return initialState;
    });

    const [selectedOption, setSelectedOption] = useState('createMultipleCatalogs');
    const [singleCatalogName, setSingleCatalogName] = useState('');
    const [catalogNameError, setCatalogNameError] = useState('');

    const isOnlyOneFolder = Object.keys(confirmedFolders).length === 1;
    const isAllUnfoldered = Object.keys(confirmedFolders).length === 1 && Object.keys(confirmedFolders)[0] === "Unfoldered";

    useEffect(() => {
      if (isOnlyOneFolder || isAllUnfoldered) {
        setSelectedOption('scheduleAllFiles');
      }
    }, [isOnlyOneFolder, isAllUnfoldered]);

    const handleToggleFolder = (folder) => {
      setConfirmedFolders(prev => {
        const newState = { ...prev };
        const folderData = newState[folder];
        
        if (folderData) {
          folderData.isSelected = !folderData.isSelected;

          // If selecting, update parent folders
          if (folderData.isSelected) {
            let parentPath = folder.split('/').slice(0, -1).join('/');
            while (parentPath) {
              if (newState[parentPath]) {
                newState[parentPath].isSelected = false;
              }
              parentPath = parentPath.split('/').slice(0, -1).join('/');
            }
          }

          // Update selectedFiles count only for this folder
          folderData.selectedFiles = folderData.isSelected ? folderData.totalFiles : 0;
        }

        return newState;
      });
    };

    const renderFolderTree = () => {
      const sortedFolders = Object.keys(confirmedFolders).sort((a, b) => a.localeCompare(b));
      const folderStructure = {};

      // Build the folder structure
      sortedFolders.forEach(fullPath => {
        const parts = fullPath.split('/');
        let currentLevel = folderStructure;
        parts.forEach((part, index) => {
          if (!currentLevel[part]) {
            currentLevel[part] = {
              fullPath: parts.slice(0, index + 1).join('/'),
              children: {}
            };
          }
          currentLevel = currentLevel[part].children;
        });
      });

      // Recursive function to render folders
      const renderFolder = (folderObj, name, level = 0) => {
        const fullPath = folderObj.fullPath;
        const folderData = confirmedFolders[fullPath] || { totalFiles: 0, selectedFiles: 0, isSelected: false };
        const hasSelectedSubfolders = Object.keys(folderObj.children).some(childName => 
          confirmedFolders[`${fullPath}/${childName}`]?.isSelected
        );

        const isUnfoldered = fullPath === "Unfoldered";
        const displayName = isUnfoldered ? currentCatalogName : name;

        const folderComponent = (
          <div key={fullPath} style={{ marginLeft: `${level * 20}px` }}>
            <div className="flex items-center p-2 bg-gray-100 rounded mb-2">
              <input
                type="checkbox"
                id={fullPath}
                checked={folderData.isSelected}
                onChange={() => handleToggleFolder(fullPath)}
                className="mr-2"
              />
              <FontAwesomeIcon icon={faFolder} className="text-yellow-500 mr-2" />
              <label htmlFor={fullPath} className="flex-grow text-sm font-medium">
                {displayName} (<span className="font-bold">{folderData.selectedFiles}</span> files)
                {hasSelectedSubfolders && <span className="ml-2 text-xs text-blue-600">(subfolder selected)</span>}
                {isUnfoldered && <span className="ml-2 text-xs text-green-600 font-semibold">(current catalog)</span>}
              </label>
            </div>
            {Object.entries(folderObj.children).map(([childName, childObj]) => 
              renderFolder(childObj, childName, level + 1)
            )}
          </div>
        );

        return folderComponent;
      };

      // Render the top-level folders
      return Object.entries(folderStructure).map(([name, obj]) => renderFolder(obj, name));
    };

    const handleCatalogNameChange = (e) => {
      setSingleCatalogName(e.target.value);
      setCatalogNameError('');
    };

    const handleConfirm = () => {
      if (selectedOption === 'scheduleAllFiles' && !singleCatalogName.trim()) {
        setCatalogNameError('Please enter a valid catalog name');
        return;
      }
      onConfirm(confirmedFolders, selectedOption, singleCatalogName);
    };

    const selectedFolderCount = Object.values(confirmedFolders).filter(f => f.isSelected && f.selectedFiles > 0).length;
    const selectedFileCount = Object.values(confirmedFolders).reduce((sum, f) => f.isSelected ? sum + f.selectedFiles : sum, 0);
  
    return (
      <div className="flex flex-col w-full h-full">
        <div className="w-full bg-white p-8 rounded-lg shadow-md flex flex-col mb-4" style={{ height: "calc(80vh - 40px)", maxHeight: "calc(80vh - 40px)" }}>
          <button onClick={onBack} className="mb-3 flex items-center text-primary hover:text-primary-dark">
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Back to Schedule Settings
          </button>
          <h2 className="text-2xl font-bold mb-5">Confirm Selected Folders</h2>
          <p className="text-sm text-gray-600 mb-4">
            <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
            You've selected <span className="font-bold text-primary">{selectedFolderCount}</span> folders, <span className="font-bold text-primary">{selectedFileCount}</span> files in total.
          </p>
          <div className="flex flex-grow overflow-hidden">
            <div className="w-3/5 pr-4 overflow-y-auto">
              <div className="space-y-2 text-sm">
                {renderFolderTree()}
              </div>
            </div>
            <div className="w-2/5 bg-gray-100 p-4 rounded-lg overflow-y-auto">
              <div className="flex items-center mb-3">
                <h3 className="text-lg font-bold p-2">Choose Scheduling Option:</h3>
              </div>
              <div className="space-y-3">
                <div className={`flex items-center p-3 border border-gray-200 rounded-xl bg-white ${isOnlyOneFolder || isAllUnfoldered ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'} transition-colors`}>
                  <input
                    type="radio"
                    id="createMultipleCatalogs"
                    checked={selectedOption === 'createMultipleCatalogs'}
                    onChange={() => setSelectedOption('createMultipleCatalogs')}
                    className="mr-2 h-4 w-4 text-primary"
                    disabled={isOnlyOneFolder || isAllUnfoldered}
                  />
                  <label htmlFor="createMultipleCatalogs" className={`text-sm font-medium ${isOnlyOneFolder || isAllUnfoldered ? 'cursor-not-allowed' : 'cursor-pointer'} flex-grow`}>
                    <span className="block text-base mb-1">Schedule in Multiple Catalogs</span>
                    <span className="block text-xs text-gray-600">
                      Will be creating <span className="font-bold text-primary">{selectedFolderCount}</span> catalogs
                    </span>
                  </label>
                </div>
                <div className="flex items-center p-3 border border-gray-200 rounded-xl bg-white hover:bg-gray-50 transition-colors">
                  <input
                    type="radio"
                    id="scheduleAllFiles"
                    checked={selectedOption === 'scheduleAllFiles'}
                    onChange={() => setSelectedOption('scheduleAllFiles')}
                    className="mr-2 h-4 w-4 text-primary"
                  />
                  <label htmlFor="scheduleAllFiles" className="text-sm font-medium cursor-pointer flex-grow">
                    <span className="block text-base mb-1">Schedule in One Catalog</span>
                    <span className="block text-xs text-gray-600">
                      <span className="font-bold text-primary">{selectedFileCount}</span> files will be scheduled in a single catalog
                    </span>
                  </label>
                </div>
                {selectedOption === 'scheduleAllFiles' && (
                  <div className="mt-4">
                    <label htmlFor="singleCatalogName" className="block text-sm font-medium text-gray-700 mb-1">
                      Catalog Name
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="singleCatalogName"
                        value={singleCatalogName}
                        onChange={handleCatalogNameChange}
                        className={`text-sm w-full p-2 pl-4 border ${catalogNameError ? 'border-red-500' : 'border-gray-300'} rounded-md focus:ring-blue-500 focus:border-blue-500`}
                        placeholder="Enter a name for the catalog"
                      />
                    </div>
                    {catalogNameError && (
                      <p className="mt-1 text-sm text-red-600">{catalogNameError}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-2">
          <button
            onClick={handleConfirm}
            className="bg-primary hover:bg-primary-dark text-white py-2 px-6 rounded-md flex items-center text-base font-bold transition-colors"
            disabled={selectedOption === 'scheduleAllFiles' && !singleCatalogName.trim()}
          >
            Confirm Schedule
          </button>
        </div>
      </div>
    );
  };

  if (isConfirmationView) {
    return (
      <div className="flex flex-col px-5 items-center w-full h-full">
        <ConfirmationView
          folders={selectedFoldersForConfirmation}
          onBack={() => setIsConfirmationView(false)}
          onConfirm={(confirmedFolders, selectedOption, singleCatalogName) => {
            setIsConfirmationView(false);
            handleCreateCatalogWithFilesAndTags(confirmedFolders, selectedOption, singleCatalogName);
          }}
          currentCatalogName={usedCatalog}
          checkedItems={checkedItems}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col pt-5 px-5 items-center w-full">
      <div className="flex justify-between w-full gap-3">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={() => setTimeSelectionModalOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} className="text-xl" />
        </button>
        <div
          className="w-3/5 bg-white p-10 rounded-lg shadow-md"
          style={{ height: "70vh", maxHeight: "70vh" }}
        >
          <h2 className="text-2xl font-bold mb-5">Select Data</h2>
          {isFolderListModalOpen ? (
            <div className="w-full h-full flex justify-center items-center overflow-y-auto">
              <FolderList
                folders={folders}
                checkedItems={checkedItems}
                searchText={searchText}
                setCheckedItems={setCheckedItems}
                integration={integration}
                setShowFilePreview={setShowFilePreview}
                filteredFolderKeys={filteredFolderKeys}
                setSearchText={setSearchText}
                mode="scheduling"
                checkedFolders={checkedFolders}
                setCheckedFolders={setCheckedFolders}
              />
            </div>
          ) : (
            <>
              {Object.keys(checkedItems).length !== 0 ? (
                <>
                  <div className="mb-5">
                    <label className="text-gray-700">
                      Files selected from current catalog:
                    </label>
                  </div>
                  <div className="flex flex-wrap items-start overflow-y-auto h-32">
                    {Object.keys(checkedItems).map(
                      (filename) =>
                        checkedItems[filename] && (
                          <span
                            key={filename}
                            className="bg-gray-100 rounded-md px-3 py-1 text-xs text-gray-700 mr-2 mb-2"
                          >
                            {filename}
                          </span>
                        ),
                    )}
                  </div>
                </>
              ) : (
                <p className="text-gray-600 text-sm">
                  You haven't selected any files from the catalog
                </p>
              )}
              <div className="mt-4">
                <button
                  className="border-blue-500 border-2 text-blue-500 hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md transition duration-300 text-sm"
                  onClick={() => setIsExternalOptionsOpen(true)}
                >
                  Connect to external data source
                </button>
              </div>
              {isExternalOptionsOpen && (
                <div className="mt-3 space-y-1.5">
                  {Object.entries(preferences.webapp_profile.DATA_STORES).map(
                    ([key, value]) => (
                      <div
                        key={key}
                        className="flex items-center p-2 bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 transition duration-300 text-sm pt-3"
                        onClick={() => handleIntegrationClick(key, "s3List")}
                      >
                        <FontAwesomeIcon
                          icon={
                            value["storage"]["type"] === "s3"
                              ? faAmazon
                              : value["storage"]["type"] === "sharepoint"
                                ? faMicrosoft
                                : faFile
                          }
                          className={`mr-1.5 ${value["storage"]["type"] === "s3"
                            ? "text-yellow-500"
                            : "text-blue-500"
                            }`}
                        />
                        <span>{`Connect to ${key}`}</span>
                      </div>
                    ),
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div
          className="w-2/5 bg-white p-10 rounded-lg shadow-md"
          style={{ height: "70vh", maxHeight: "70vh" }}
        >
          <h2 className="text-2xl font-bold mb-5">Configure Schedule</h2>
          <hr className="mb-5" />
          <form className="space-y-6">
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Start date and time
              </label>
              <ReactDatePicker
                id="date"
                selected={selectedDate || new Date()} // Set default to current date and time
                onChange={(date) => setSelectedDate(date)}
                showTimeSelect
                showTimeInput
                dateFormat="MMM d, yyyy h:mm aa"
                minDate={new Date()}
                minTime={minTime}
                maxTime={maxTime}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 cursor-pointer text-sm"
                popperPlacement="bottom-start"
                calendarClassName="bg-white shadow-lg rounded-lg border border-gray-200"
                showPopperArrow={false}
              />
            </div>
            {type === "Periodic" && (
              <div className="space-y-4">
                <div className="bg-green-50 border-l-4 border-primary p-4">
                  <p className="text-sm text-green-700">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                    Periodic scheduling means that the selected folders will be
                    continuously scanned for:
                    <ul className="list-disc list-inside mt-2 ml-4 mb-2">
                      <li>1. Changes to existing files</li>
                      <li>2. Modified files</li>
                      <li>3. New files added to the folder</li>
                    </ul>
                    Any detected changes will trigger automatic tagging of the
                    affected files.
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="period"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Scheduling period
                  </label>
                  <select
                    id="period"
                    value={selectedPeriod}
                    onChange={(e) => setSelectedPeriod(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="HALF_HOUR">Every 30 minutes</option>
                    <option value="MONTHLY">Monthly</option>
                    <option value="WEEKLY">Weekly</option>
                    <option value="DAILY">Daily</option>
                  </select>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      {isWarningModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg">
            <p className="text-lg mb-4">
              Please select a start date and time before confirming the
              schedule.
            </p>
            <button
              onClick={() => setIsWarningModalOpen(false)}
              className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="flex w-full justify-between items-center mt-6">
        <div className="bg-gray-100 rounded-md p-4 flex items-center max-w-lg">
          <FontAwesomeIcon icon={faInfoCircle} className="text-primary mr-3" />
          <p className="text-sm text-gray-700">
            {
              "To preview scheduled tasks, go to Account -> Tag -> Scheduled Task Console"
            }
          </p>
        </div>
        <button
          onClick={handleCreateCatalogButtonClick}
          disabled={Object.keys(checkedItems).length === 0}
          className="bg-primary text-white text-lg font-bold py-3 px-10 rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default ScheduleSettingComponent;
