import "./DataCatalog.css";
import LoadComponent from "../../../../utilities/LoadComponent/LoadComponent";
import SearchBar from "../../../../utilities/SearchBar/SearchBar";
import DataList from "./DataCatalogComponents/DataList/DataList";
import { DataContext } from "../../../../../context/DataContext";
import TagDefinitions from "./TagDefinitions";
import useCatalogData from "./useCatalogData";
import { useContext } from "react";

export function DataCatalog() {
  const {
    setSearchTerm,
    searchTerm,
    isLoading,
    isTagDefinitionsExpanded,
    setIsTagDefinitionsExpanded,
  } = useContext(DataContext);
  const { answerLoading, setAnswerLoading } = useCatalogData();

  if (isLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="flex w-full h-full gap-2 flex-row">
      <div
        className={`flex border flex-col rounded-md h-auto overflow-hidden transition-all duration-300 ${
          isTagDefinitionsExpanded
            ? "w-[calc(100%-20vw-1rem)]"
            : "w-[calc(100%-80px-1rem)]"
        }`}
      >
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <DataList
          answerLoading={answerLoading}
          setAnswerLoading={setAnswerLoading}
        />
      </div>
      <div
        className={`flex border h-auto rounded-md flex-col gap-4 overflow-hidden transition-all duration-300 ${
          isTagDefinitionsExpanded ? "w-[20vw]" : "w-20"
        }`}
      >
        <TagDefinitions
          isExpanded={isTagDefinitionsExpanded}
          setIsExpanded={setIsTagDefinitionsExpanded}
        />
      </div>
    </div>
  );
}
