import "./Home.css";
import { useEffect, useContext } from "react";
import LoadComponent from "../../utilities/LoadComponent/LoadComponent";
import NavigationBar from "../../utilities/NavigationBar/NavigationBar";
import { DataCatalog } from "./HomeComponents/DataCatalog/DataCatalog";
import AddNewTag from "./HomeComponents/AddNewTag/AddNewTag";
import RulesScreen from "./HomeComponents/Rules/Rules";
import Tags from "./HomeComponents/DataCatalog/DataCatalogComponents/Tags/Tags";
import { DataContext } from "../../../context/DataContext";
import UserPreferences from "./HomeComponents/Preferences/Preferences";
import { countStatusValues } from "../../utilities/functions/utils";
import { TagContext } from "../../../context/TagContext";
import AddTaggingRules from "./HomeComponents/TaggingRules/TaggingRules";
import AutoCreateTag from "./HomeComponents/AddNewTag/AutoCreateTag";

function Home() {
  const {
    isLoading,
    showScreen,
    // Functions
    fetchInitialCatalog,
    fetchInitialTaggerList,
    preferences,
    catalogFiles,
    usedCatalog,
    setCurrentProcessCount,
    setCatalogGetsRenamed,
  } = useContext(DataContext);

  const { prevCount } = useContext(TagContext);

  useEffect(() => {
    setCatalogGetsRenamed(true);
    async function fetchData() {
      await Promise.all([
        fetchInitialCatalog(usedCatalog),
        fetchInitialTaggerList(),
      ]);
    }
    fetchData();
    setCatalogGetsRenamed(false);
  }, []);

  useEffect(() => {
    const newCount = countStatusValues(catalogFiles, "Processed").Processed;

    const update = prevCount + newCount;
    setCurrentProcessCount(update);
  }, [catalogFiles]);

  if (isLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="Home">
      {showScreen !== "chat" && <NavigationBar />}
      <div className="p-1 bg-parentContainerBackground  bg-opacity-30 h-full dark:bg-black overflow-y-auto w-full flex justify-center items-center">
        {showScreen === "catalog" ? (
          <DataCatalog />
        ) : showScreen === "addNewTag" ? (
          <AddNewTag />
        ) : showScreen === "autoCreateTag" ? (
          <AutoCreateTag />
        ) : showScreen === "rules" ? (
          <RulesScreen />
        ) : showScreen === "taggingRules" ? (
          <AddTaggingRules />
        ) : showScreen === "preferences" ? (
          <UserPreferences />
        ) : showScreen === preferences.system.SENSITIVITY_TAGS_NAME ? (
          <div className="home-label-parent-container w-full max-w-6xl">
            <p className="home-label-title">Existing Tags</p>
            <div className="home-label-container">
              <Tags title={"Classification Tags"} tagTypes={["llm"]} />
              <Tags title={"Sensitivity Tags"} tagTypes={["sensitivity"]} />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default Home;
