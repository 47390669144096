import React, { useContext, useState, useEffect } from 'react';
import Auth from "../../../auth/AuthProvider";
import { ENDPOINTS } from "../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../constants/fixedValues";
import { sendRequest } from "../functions/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faSyncAlt, faArrowLeft, faFolder, faSort } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from "../../../context/DataContext";
import { toast } from "../../../components/utilities/Toast";
import { toZonedTime, format } from 'date-fns-tz';
import {
  useCatalogNames,
  useCreateCatalogMutation,
} from "../../../api/queryHooks";

export const ScheduledTasksModal = ({ isModalOpen }) => {
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');
  const [visibleDetails, setVisibleDetails] = useState(null); // To track which task's details are visible
  const [detailsData, setDetailsData] = useState({ successCount: 0, failCount: 0, unfinishedCount: 0 });
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // To control the details modal visibility
  const [visibleFileList, setVisibleFileList] = useState(null); // To track which file list is visible
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { preferences } = useContext(DataContext);
  const [activeTaskIds, setActiveTaskIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchColumn, setSearchColumn] = useState('catalog'); // search by 'catalog' or 'status'
  const [fileDetails, setFileDetails] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [expandedTags, setExpandedTags] = useState({});
  const [fileSearchTerm, setFileSearchTerm] = useState('');
  const [fileSortColumn, setFileSortColumn] = useState(null);
  const [fileSortDirection, setFileSortDirection] = useState('asc');

  const { data: catalogNames } = useCatalogNames();

  const SortIcon = ({ isAsc }) => (
    <FontAwesomeIcon icon={isAsc ? faSortUp : faSortDown} />
  );

  const toggleSortDate = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
    const sortedTasks = [...filteredTasks].sort((a, b) => {
      const dateA = new Date(a.scheduled_at);
      const dateB = new Date(b.scheduled_at);
      return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setFilteredTasks(sortedTasks);
  };

  const formatDate = (utcDateString) => {
    // Convert UTC date to the local time zone 
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedTime = toZonedTime(new Date(utcDateString), timeZone);
    return format(zonedTime, 'yyyy-MM-dd HH:mm:ss', { timeZone });
  };
  
  const fetchScheduledTasks = async () => {
    setIsLoading(true);
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
      };
      const response = await sendRequest(sendDetails, ENDPOINTS["get_user_scheduled_tasks"]);
      const responseData = await response.json();
      
      const tasksWithLocalTime = responseData.scheduled_tasks.map(task => ({
        ...task,
        scheduled_at: formatDate(task.scheduled_at),
        catalog_name: task.catalog_name || 'N/A'
      }));
      
      setScheduledTasks(tasksWithLocalTime || []);

      // Fetch task details if a task is currently selected
      if (selectedTaskId) {
        await fetchFileDetails(selectedTaskId);
      }
      await fetchActiveTasks();
    } catch (error) {
      console.error('Failed to fetch scheduled tasks:', error);
      setScheduledTasks([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchScheduledTasks();
    }
  }, [isModalOpen]);

  useEffect(() => {
    const filtered = scheduledTasks.filter(task => {
      const matchesStatus = statusFilter ? task.status.toLowerCase() === statusFilter.toLowerCase() : true;
      const matchesSearch = searchTerm.toLowerCase() === '' ? true :
        searchColumn === 'catalog' 
          ? task.catalog_name.toLowerCase().includes(searchTerm.toLowerCase())
          : getDisplayStatus(task).toLowerCase().includes(searchTerm.toLowerCase());
      return matchesStatus && matchesSearch;
    });
    const sorted = [...filtered].sort((a, b) => {
      const dateA = new Date(a.scheduled_at);
      const dateB = new Date(b.scheduled_at);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setFilteredTasks(sorted);
  }, [statusFilter, scheduledTasks, sortDirection, searchTerm, searchColumn]);


  const fetchActiveTasks = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const creds = user.username;
      const email = user.attributes.email;

      const sendDetails = {
        username: creds,
        user_email: email,
      };

      const response = await sendRequest(sendDetails, ENDPOINTS["get_tasks_status"]);
      const responseData = await response.json();

      const activeTaskIds = [...new Set(responseData.activeTasks.map(task => task.id))];
      setActiveTaskIds(activeTaskIds);

    } catch (error) {
      console.error('Failed to fetch active tasks:', error);
      setActiveTaskIds([]);
    }
  };

  const fetchFileDetails = async (taskId) => {
    setIsLoading(true);
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
        task_id: taskId,
        catalog_name: scheduledTasks.find(task => task.task_id === taskId)?.catalog_name || '',
      };
      const response = await sendRequest(sendDetails, ENDPOINTS["get_schedule_task_file_details"]);
      const responseData = await response.json();
      
      if (!responseData || !responseData.file_details) {
        console.warn("No file details available");
        setFileDetails([]);
        return;
      }

      const formattedFileDetails = Object.entries(responseData.file_details).map(([fileName, details]) => ({
        fileName,
        tagsCompleted: details.completed_tags,
        tagsScheduled: details.scheduled_tags,
        tagsExecuting: details.executing_tags,
        tagsFailed: details.failed_tags,
        completeRatio: calculateCompleteRatio(details)
      }));

      setFileDetails(formattedFileDetails);
    } catch (error) {
      console.error('Failed to fetch file details:', error);
      setFileDetails([]);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateCompleteRatio = (details) => {
    const total = details.completed_tags.length + details.scheduled_tags.length + 
                  details.executing_tags.length + details.failed_tags.length;
    return total === 0 ? 0 : Math.round((details.completed_tags.length / total) * 100);
  };

  const toggleDetails = async (taskId) => {
    if (selectedTaskId === taskId) {
      // hide the details
      setSelectedTaskId(null);
      setFileDetails([]);
      setExpandedTags({}); // Reset expanded tags when leaving details view
      setSelectedFiles([]); // Reset selected files when leaving details view
    } else {
      // show the details
      const selectedTask = scheduledTasks.find(task => task.task_id === taskId);
      if (selectedTask && !catalogNames.includes(selectedTask.catalog_name)) {
        toast.error({
          title: "Catalog Deleted",
          description: "The catalog associated with this task has been deleted.",
        });
        return;
      }
      setSelectedTaskId(taskId);
      await fetchFileDetails(taskId);
    }
  };

  const toggleFileList = (listType) => {
    setVisibleFileList(visibleFileList === listType ? null : listType);
  };

  const handleFileSelect = (fileName) => {
    setSelectedFiles(prevSelected =>
      prevSelected.includes(fileName)
        ? prevSelected.filter(name => name !== fileName)
        : [...prevSelected, fileName]
    );
  };

  const toggleAllIncompleteFiles = () => {
    const incompleteFiles = fileDetails.filter(file => file.completeRatio < 100);
    const incompleteFileNames = incompleteFiles.map(file => file.fileName);
    
    if (incompleteFileNames.every(fileName => selectedFiles.includes(fileName))) {
      setSelectedFiles(prevSelected => prevSelected.filter(fileName => !incompleteFileNames.includes(fileName)));
    } else {
      setSelectedFiles(prevSelected => [...new Set([...prevSelected, ...incompleteFileNames])]);
    }
  };

  const areAllIncompleteFilesSelected = () => {
    const incompleteFiles = fileDetails.filter(file => file.completeRatio < 100);
    return incompleteFiles.every(file => selectedFiles.includes(file.fileName));
  };

  const rerunIncompleteTags = async () => {
    try {
      const selectedTask = scheduledTasks.find(task => task.task_id === selectedTaskId);
      if (selectedTask && getDisplayStatus(selectedTask).toLowerCase() === 'aborted') {
        toast.error({
          title: "Error",
          description: "You cannot re-run the aborted tasks.",
        });
        return;
      }

      const creds = (await Auth.currentAuthenticatedUser()).username;
      
      const failed_tags_dict = {};
      selectedFiles.forEach(fileName => {
        const fileDetail = fileDetails.find(file => file.fileName === fileName);
        if (fileDetail && fileDetail.tagsFailed.length > 0) {
          failed_tags_dict[fileName] = fileDetail.tagsFailed;
        }
      });

      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
        task_id: selectedTaskId,
        catalog_name: scheduledTasks.find(task => task.task_id === selectedTaskId)?.catalog_name || '',
        rerun_tags_dict: failed_tags_dict,
      };

      const response = await sendRequest(sendDetails, ENDPOINTS["rerun_schedule_tags"]);
      const responseData = await response.json();

      if (response.ok) {
        toast.success({
          title: "Success",
          description: "Re-triggering incomplete tags has been scheduled successfully.",
        });
        await fetchFileDetails(selectedTaskId);
      } else {
        throw new Error(responseData.message || "Failed to schedule re-run");
      }
    } catch (error) {
      console.error('Failed to re-run incomplete tags:', error);
      toast.error({
        title: "Error",
        description: "Failed to schedule re-run on incomplete tags. Please try again.",
      });
    }
  };

  const rerunAllIncompleteTags = async () => {
    try {
      const selectedTask = scheduledTasks.find(task => task.task_id === selectedTaskId);
      if (selectedTask && getDisplayStatus(selectedTask).toLowerCase() === 'aborted') {
        toast.error({
          title: "Error",
          description: "You cannot re-run the aborted tasks.",
        });
        return;
      }

      const creds = (await Auth.currentAuthenticatedUser()).username;
      
      const incomplete_tags_dict = {};
      fileDetails.forEach(file => {
        if (file.completeRatio < 100) {
          incomplete_tags_dict[file.fileName] = [
            ...file.tagsScheduled,
            ...file.tagsExecuting,
            ...file.tagsFailed
          ];
        }
      });

      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
        task_id: selectedTaskId,
        catalog_name: scheduledTasks.find(task => task.task_id === selectedTaskId)?.catalog_name || '',
        rerun_tags_dict: incomplete_tags_dict,
      };

      const response = await sendRequest(sendDetails, ENDPOINTS["rerun_schedule_tags"]);
      const responseData = await response.json();

      if (response.ok) {
        toast.success({
          title: "Success",
          description: "Re-triggering all incomplete tags has been scheduled successfully.",
        });
        await fetchFileDetails(selectedTaskId);
      } else {
        throw new Error(responseData.message || "Failed to schedule re-run");
      }
    } catch (error) {
      console.error('Failed to re-run all incomplete tags:', error);
      toast.error({
        title: "Error",
        description: "Failed to schedule re-run on all incomplete tags. Please try again.",
      });
    }
  };

  const getDisplayStatus = (task) => {
    if (task.status.toLowerCase() === 'running' && !activeTaskIds.includes(task.task_id)) {
      return 'completed';
    }
    return task.status;
  };

  const toggleTagExpansion = (fileName, tagType) => {
    setExpandedTags(prev => ({
      ...prev,
      [fileName]: {
        ...prev[fileName],
        [tagType]: !prev[fileName]?.[tagType]
      }
    }));
  };

  const getTagColor = (tagType) => {
    switch (tagType) {
      case 'Completed':
        return 'text-green-600';
      case 'Scheduled':
        return 'text-blue-600';
      case 'Executing':
        return 'text-orange-500';
      case 'Failed':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  const getTagBgColor = (tagType) => {
    switch (tagType) {
      case 'Completed':
        return 'bg-green-50';
      case 'Scheduled':
        return 'bg-blue-50';
      case 'Executing':
        return 'bg-orange-50';
      case 'Failed':
        return 'bg-red-50';
      default:
        return 'bg-gray-50';
    }
  };

  const renderTagCount = (file, tagType) => {
    const tags = file[`tags${tagType}`];
    const isExpanded = expandedTags[file.fileName]?.[tagType];
    const colorClass = getTagColor(tagType);
    const bgColorClass = getTagBgColor(tagType);

    return (
      <div>
        <span 
          className={`cursor-pointer hover:underline ${colorClass}`}
          onClick={() => toggleTagExpansion(file.fileName, tagType)}
        >
          <span className={tags.length > 0 ? 'font-bold' : ''}>{tags.length}</span>
        </span>
        {isExpanded && (
          <div className="mt-2 text-xs">
            <div className="flex flex-wrap gap-1 max-w-xs">
              {tags.map((tag, index) => (
                <span 
                  key={index} 
                  className={`inline-block px-2 py-1 rounded-md ${bgColorClass} text-gray-700 border border-gray-300 shadow-sm`}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const sortFileDetails = (column) => {
    let sortedFiles = [...fileDetails];
    const isAsc = fileSortColumn === column && fileSortDirection === 'asc';
    
    sortedFiles.sort((a, b) => {
      let valueA, valueB;
      switch (column) {
        case 'scheduledTags':
          valueA = a.tagsScheduled.length;
          valueB = b.tagsScheduled.length;
          break;
        case 'executingTags':
          valueA = a.tagsExecuting.length;
          valueB = b.tagsExecuting.length;
          break;
        case 'completedTags':
          valueA = a.tagsCompleted.length;
          valueB = b.tagsCompleted.length;
          break;
        case 'failedTags':
          valueA = a.tagsFailed.length;
          valueB = b.tagsFailed.length;
          break;
        case 'completeRatio':
          valueA = a.completeRatio;
          valueB = b.completeRatio;
          break;
        default:
          return 0;
      }
      
      if (valueA < valueB) return isAsc ? -1 : 1;
      if (valueA > valueB) return isAsc ? 1 : -1;
      return 0;
    });

    setFileDetails(sortedFiles);
    setFileSortColumn(column);
    setFileSortDirection(isAsc ? 'desc' : 'asc');
  };

  const renderSortIcon = (column) => {
    if (fileSortColumn !== column) {
      return <FontAwesomeIcon icon={faSort} className="ml-1 text-gray-400" />;
    }
    return fileSortDirection === 'asc' ? 
      <FontAwesomeIcon icon={faSortUp} className="ml-1" /> : 
      <FontAwesomeIcon icon={faSortDown} className="ml-1" />;
  };

  const renderFileListTable = (files, title, goBack) => {
    const filteredFiles = files.filter(file => 
      file.fileName.toLowerCase().includes(fileSearchTerm.toLowerCase())
    );

    return (
      <div className="mt-4 bg-white rounded-lg shadow-md p-4 relative flex flex-col h-full">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <button
              onClick={goBack}
              className="mr-3 text-gray-600 hover:text-gray-800 transition-colors duration-200"
              title="Back to Scheduled Tasks"
            >
              <FontAwesomeIcon icon={faArrowLeft} size="base" />
            </button>
            <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
          </div>
        </div>
        <div className="flex items-center mb-4 text-xs">
          <input
            type="text"
            placeholder="Search files..."
            value={fileSearchTerm}
            onChange={(e) => setFileSearchTerm(e.target.value)}
            className="px-3 py-2 border rounded-md mr-2 flex-grow"
          />
        </div>
        <div className="overflow-hidden flex-grow" style={{ height: 'calc(100vh - 300px)' }}>
          <div className="overflow-auto h-full">
            <table className="w-full text-xs table-auto">
              <thead className="bg-gray-200 text-gray-700 font-bold sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-3 text-sm font-medium border-b border-gray-300 text-left">File Name</th>
                  <th 
                    className="px-6 py-3 text-sm font-medium border-b border-gray-300 text-left cursor-pointer"
                    onClick={() => sortFileDetails('scheduledTags')}
                  >
                    Scheduled Tags {renderSortIcon('scheduledTags')}
                  </th>
                  <th 
                    className="px-6 py-3 text-sm font-medium border-b border-gray-300 text-left cursor-pointer"
                    onClick={() => sortFileDetails('executingTags')}
                  >
                    Executing Tags {renderSortIcon('executingTags')}
                  </th>
                  <th 
                    className="px-6 py-3 text-sm font-medium border-b border-gray-300 text-left cursor-pointer"
                    onClick={() => sortFileDetails('completedTags')}
                  >
                    Completed Tags {renderSortIcon('completedTags')}
                  </th>
                  <th 
                    className="px-6 py-3 text-sm font-medium border-b border-gray-300 text-left cursor-pointer"
                    onClick={() => sortFileDetails('failedTags')}
                  >
                    Failed Tags {renderSortIcon('failedTags')}
                  </th>
                  <th 
                    className="px-6 py-3 text-sm border-b border-gray-300 text-left font-bold cursor-pointer"
                    onClick={() => sortFileDetails('completeRatio')}
                  >
                    Complete Ratio {renderSortIcon('completeRatio')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white text-xs divide-y divide-gray-200">
                {filteredFiles.map((file, index) => (
                  <tr key={index} className="h-10 hover:bg-gray-50">
                    <td className="px-6 py-2 text-sm relative">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedFiles.includes(file.fileName)}
                          onChange={() => handleFileSelect(file.fileName)}
                          className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer mr-4 flex-shrink-0"
                        />
                        <div className="truncate max-w-xs">
                          <span className="cursor-default group">
                            {file.fileName}
                            <span className="invisible group-hover:visible absolute z-10 bg-gray-800 text-white text-xs rounded py-1 px-2 left-6 top-full mt-1 whitespace-nowrap">
                              {file.fileName}
                            </span>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-sm">{renderTagCount(file, 'Scheduled')}</td>
                    <td className="px-6 py-2 text-sm">{renderTagCount(file, 'Executing')}</td>
                    <td className="px-6 py-2 text-sm">{renderTagCount(file, 'Completed')}</td>
                    <td className="px-6 py-2 text-sm">{renderTagCount(file, 'Failed')}</td>
                    <td className="px-6 py-2 text-sm">
                      {file.completeRatio}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <button
            onClick={toggleAllIncompleteFiles}
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 text-sm shadow-sm"
          >
            {areAllIncompleteFilesSelected() ? 'Deselect' : 'Select'} All Incomplete Files
          </button>
          <div className="flex space-x-2">
            <button
              onClick={rerunIncompleteTags}
              disabled={selectedFiles.length === 0}
              className={`bg-red-600 text-white px-4 py-2 rounded-md hover:bg-green-800 text-sm shadow-sm ${
                selectedFiles.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              Re-run Failed Tags
            </button>
            <button
              onClick={rerunAllIncompleteTags}
              className="bg-primary text-white px-4 py-2 rounded-md hover:bg-green-800 text-sm shadow-sm"
            >
              Re-run All Incomplete Tags
            </button>
          </div>
        </div>
      </div>
    );
  };

  const abortTask = async (taskId) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const creds = user.username;
      const email = user.attributes.email;

      const revokeResponse = await sendRequest(
        {
          task_id: taskId,
          [preferences.system.API_USERNAME_KEYWORD]: creds,
        },
        ENDPOINTS.revoke_task,
      );

      if (revokeResponse.ok) {
        toast.success({
          title: "Success",
          description: `Task ${taskId} has been successfully aborted.`,
        });

        setActiveTaskIds((prevIds) => prevIds.filter((id) => id !== taskId));

        await sendRequest(
          {
            username: creds,
            task_id: taskId,
            status: "aborted",
          },
          ENDPOINTS.update_schedule_task_status,
        );
      } else {
        toast.error({
          title: "Error",
          description: `Failed to abort task ${taskId}. Please try again.`,
        });
      }
    } catch (error) {
      console.error("Error aborting the task:", error);
      toast.error({
        title: "Error",
        description: `An unexpected error occurred while trying to abort task ${taskId}.`,
      });
    }
  };

  const renderCatalogWithIcon = (catalogName) => {
    return (
      <div className="flex items-center justify-center">
        <FontAwesomeIcon icon={faFolder} className="text-yellow-500 mr-2" />
        <span>{catalogName}</span>
      </div>
    );
  };

  if (!isModalOpen) return null;
  return (
    <div className="font-sans bg-gray-50 p-6 rounded-lg w-full h-screen overflow-auto">
      {isLoading ? (
        <div className="flex justify-center items-center h-full text-lg text-gray-700">Loading...</div>
      ) : (
        <div className="flex flex-col h-full w-full gap-2">
          <div className="w-full text-center text-lg font-bold mb-2 ml-3 py-2 flex justify-between items-center text-gray-800 border-b-2 border-gray-300">
            {selectedTaskId ? 'File Details' : 'Scheduled Tasks'}
            <button
              onClick={fetchScheduledTasks}
              className="bg-gray-400 text-white px-3 py-2 rounded-md hover:bg-gray-600 text-sm shadow-sm"
            >
              <FontAwesomeIcon icon={faSyncAlt} />
            </button>
          </div>
          
          {!selectedTaskId && (
            <div className="flex items-center mb-4 text-xs">
              <input
                type="text"
                placeholder={`Search by ${searchColumn}...`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="px-3 py-2 border rounded-md mr-2 flex-grow"
              />
              <select
                value={searchColumn}
                onChange={(e) => setSearchColumn(e.target.value)}
                className="px-3 py-2 border rounded-md"
              >
                <option value="catalog">Catalog</option>
                <option value="status">Status</option>
              </select>
            </div>
          )}

          {selectedTaskId ? (
            renderFileListTable(
              fileDetails,
              `File Details`,
              () => {
                setSelectedTaskId(null);
                setFileDetails([]);
                setFileSearchTerm('');
                setExpandedTags({});
                setSelectedFiles([]);
              }
            )
          ) : (
            <div className="flex flex-col flex-grow h-[calc(100vh-150px)] overflow-auto">
              <div className="w-full">
                <table className="w-full table-auto bg-white rounded-lg shadow-md">
                  <thead className="bg-gray-200 text-black sticky top-0 z-10">
                    <tr>
                      <th className="px-6 py-2 text-sm text-center">Task ID</th>
                      <th className="px-6 py-2 text-sm text-center">Catalog</th>
                      <th className="px-6 py-2 text-sm text-center cursor-pointer" onClick={toggleSortDate}>
                        Schedule Time <SortIcon isAsc={sortDirection === 'asc'} />
                      </th>
                      <th className="px-6 py-2 text-sm text-center">Status</th>
                      <th className="px-6 py-2 text-sm text-center">Summary</th>
                      <th className="px-6 py-2 text-sm text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredTasks.length === 0 ? (
                      <tr>
                        <td colSpan={6} className="text-center py-8 text-gray-500">No scheduled tasks</td>
                      </tr>
                      ) : (
                        filteredTasks.map(task => (
                          <tr key={task.task_id} className="hover:bg-gray-100 transition duration-150 ease-in-out text-sm text-center">
                            <td className="px-6 py-2">{task.task_id}</td>
                            <td className="px-6 py-2">{renderCatalogWithIcon(task.catalog_name)}</td>
                            <td className="px-6 py-2">{task.scheduled_at}</td>
                            <td className="px-6 py-2">
                              <div className={`font-bold ${
                                getDisplayStatus(task).toLowerCase() === 'aborted' ? 'text-orange-600' :
                                getDisplayStatus(task).toLowerCase() === 'completed' ? 'text-green-600' :
                                getDisplayStatus(task).toLowerCase() === 'scheduled' ? 'text-yellow-500' :
                                getDisplayStatus(task).toLowerCase() === 'running' ? 'text-blue-600' :
                                'text-red-600'
                              }`}>
                                {getDisplayStatus(task)}
                              </div>
                            </td>
                            <td className="px-6 py-2">
                              <div>
                                <button
                                onClick={() => toggleDetails(task.task_id)}
                                className="ml-4 bg-primary text-white px-4 py-2 rounded-md hover:bg-green-800 text-sm shadow-sm"
                              >
                                {selectedTaskId === task.task_id ? 'Hide Details' : 'Show Details'}
                              </button>
                              {visibleDetails === task.task_id && detailsData && (
                                <div className="mt-2 p-3 bg-gray-50 border border-gray-200 rounded-lg shadow-sm">
                                  {detailsData.message ? (
                                    <div className="text-sm mt-2 text-red-600">
                                      <p>
                                        No file details available,<br />please try later
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="text-sm text-gray-700">
                                      <p
                                        onClick={() => toggleFileList('successFiles')}
                                        className="cursor-pointer hover:underline hover:text-blue-600 hover:bg-gray-100 p-2 rounded-md transition-colors duration-200"
                                      >
                                        <strong>{detailsData.successCount}</strong> files succeeded <span className="text-blue-600">›</span>
                                      </p>
                                      <p
                                        onClick={() => toggleFileList('failFiles')}
                                        className="cursor-pointer hover:underline hover:text-blue-600 hover:bg-gray-100 p-2 rounded-md transition-colors duration-200"
                                      >
                                        <strong>{detailsData.failCount}</strong> files failed <span className="text-blue-600">›</span>
                                      </p>
                                      <p
                                        onClick={() => toggleFileList('unfinishedFiles')}
                                        className="cursor-pointer hover:underline hover:text-blue-600 hover:bg-gray-100 p-2 rounded-md transition-colors duration-200"
                                      >
                                        <strong>{detailsData.unfinishedCount}</strong> files unfinished <span className="text-blue-600">›</span>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            {activeTaskIds.includes(task.task_id) && (
                              <button
                                onClick={() => abortTask(task.task_id)}
                                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 text-sm shadow-sm"
                              >
                                Abort
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
